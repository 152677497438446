(function () {
    'use strict';

    angular.module('UndergroundWebApp').controller('ContainerOverviewV2Controller', ContainerOverviewV2Controller);

    ContainerOverviewV2Controller.$inject = [
        '$q',
        '$rootScope',
        '$scope',
        '$stateParams',
        '$kWindow',
        'formTypes',
        'containerService',
        'locationsService',
        'containerEditModalWidth',
        'filterStateService',
        'kendoGridUtility',
        'maxContainerOverviewSensorColumns',
        'deviceUtility',
        '$translate',
    ];

    const overviewTableVersion = 1.0;
    const defaultSensorSystemCount = 2;

    function ContainerOverviewV2Controller(
        $q,
        $rootScope,
        $scope,
        $stateParams,
        $kWindow,
        formTypes,
        containerService,
        locationsService,
        containerEditModalWidth,
        filterStateService,
        kendoGridUtility,
        maxContainerOverviewSensorColumns,
        deviceUtility,
        $translate,
    ) {
        var containerOverviewGridData = [];
        var locations = [];

        const defaultGrouping = [
            {
                field: "locationName",
                dir: "asc",
                aggregates: []
            }
        ];

        $scope.noCache = $stateParams.noCache;
        $scope.excelFileName = 'Containeroversikt.xls';

        $scope.isEditVisible = isEditVisible;
        $scope.exportToExcel = exportToExcel;
        $scope.openNewContainerModal = openNewContainerModal;
        $scope.openUpdateContainerModal = openUpdateContainerModal;

        $scope.displayedLocationAmount = 0;
        $scope.allLocationAmount= 0;

        const persistOptions = kendoGridUtility.persistOptions(overviewTableVersion);
        $scope.containerOverviewGridOptions = {
            dataSource: {
                transport: {
                    read: readContainerOverviewGridData
                },
                schema: {
                    model: {
                        fields: {
                            displayText: {},
                            locationName: {},
                            commune: {},
                            containerNumber: {},
                            containerExternalId: {},
                            fraction: {},
                            battery: { type: 'number' },
                            fill: { type: 'number' },
                            signal: { type: 'number' },
                            sensor: {},
                            sensorSystemId: { type: 'number' },
                            updateTime: { type: 'date' },
                            dateWhenFull: { type: 'date' },
                            lastEmptyDate: { type: 'date' },
                        }
                    }
                },
                pageSize: 70,
                sort: filterStateService.getSortFromQueryString({
                    field: 'locationName',
                    dir: 'asc'
                }),
                filter: filterStateService.getFilterFromQueryString(),
            },
            sortable: true,
            filterable: {
                mode: "row",
                extra: false,
                operators: {
                    string: {
                        "contains": $translate.instant('ACCESS_CONTROL_CONTROLLER_ACCESS_ITEM_OVERVIEW_FILTER_OPERATOR_CONTAINS')
                    }
                }   
            },
            noRecords: true,
            resizable: true,
            messages: {
                noRecords: ''
            },
            scrollable: {
                virtual: true
            },
            selectable: 'row',
            groupable: {
                messages: {
                  empty: $translate.instant('G_DROP_COLUMNS_HERE')
                }
            },
            group: function(e) {
                saveGroupedStatusToLocalStorage();
            },
            ungroup: function(e) {
                saveGroupedStatusToLocalStorage();
            },
            columns: getColumns(defaultSensorSystemCount),
            columnMenu: true,
            excel: {
                fileName: $scope.excelFileName,
                filterable: true,
                allPages: true
            },
            dataBound: function(e){
                var grid = this;
                this.lockedTable.find(".k-grouping-row").each(function(index) {
                  var arrow = $(this).find("a");
                  grid.tbody.find(".k-grouping-row:eq("+index+") td").text($(this).text())
                  $(this).find("p").text(" ").append(arrow);
                })
            },
            excelExport: containerOverviewGridExcelExport,
            change: onRowSelected,
            sort: filterStateService.storeGridSortInQueryString,
            filter: filterStateService.storeGridFilterInQueryString,
            filterMenuInit: kendoGridUtility.sortMulticheckboxFilterValues('commune'),
            columnMenuInit: kendoGridUtility.excludeColumnFromMenuList('actions'),
            columnHide: persistOptions,
            columnShow: persistOptions,
            columnReorder: persistOptions,
        };

        function readContainerOverviewGridData(e) {
            e.success(containerOverviewGridData);
        }

        function getColumns(sensorSystemsCount) {
            const columns = [
                {
                    field: 'actions',
                    title: ' ',
                    width: '50px',
                    template: '<div ng-show="isEditVisible()" class="grid-tooltip edit-action-button" ng-click="openUpdateContainerModal(dataItem)"><i class="fa fa-1-5x fa-pencil" aria-hidden="true"></div>',
                    filterable: false,
                    sortable: false,
                    groupable: false,
                    locked: true,
                    lockable: false
                },
                {
                    field: 'displayText',
                    title: $translate.instant("G_ID"),
                    width: '140px',
                    lockable: false,
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        }
                    }
                },
                {
                    field: 'locationName',
                    title: $translate.instant("G_ADDRESS"),
                    width: '222px',
                    lockable: false,
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        } 
                    },
                    template: '<a href="#= locationHref#">#= locationName#</>'
                },
                {
                    field: 'commune',
                    title: $translate.instant("G_MUNINCIPALITY"),
                    width: '90px',
                    lockable: false,
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        }
                    }
                },
                { 
                    field: 'containerNumber', 
                    title: $translate.instant("CONTAINER_OVERVIEW_CONTAINER"), 
                    width: '100px',
                    lockable: false,
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        }
                    }
                },
                { 
                    field: 'containerExternalId', 
                    title: $translate.instant("G_EXTERNAL_ID"), 
                    width: '100px',
                    lockable: false,
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        }
                    }
                },
                { 
                    field: 'containerType', 
                    title: $translate.instant("CONTAINER_OVERVIEW_CONTAINER_TYPE"), 
                    width: '160px',
                    lockable: false,
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        }
                    }
                },
                {
                    field: 'fraction',
                    title: $translate.instant("CONTAINER_OVERVIEW_FRACTION"),
                    width: '100px',
                    lockable: false,
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        },
                        multi: true,
                    }
                },
                {
                    field: 'signal',
                    title: $translate.instant("CONTAINER_OVERVIEW_CONTROLLER_TABLE_SIGNAL"),
                    template: '<span class="td-fill-span #: SignalClass#" >#: signal#</span>',
                    width: '70px',
                    lockable: false,
                    filterable: {
                        extra: true,  cell: {
                            showOperators: false,
                            operator: "lte",
                            delay: 200000,  // Adjust if necessary; this is a very long delay (200 seconds)
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        },
                        operators: {
                            number: {
                                gte: $translate.instant("GRID_FILTER_GTE_OPERATOR"),
                                lte: $translate.instant("GRID_FILTER_LTE_OPERATOR")
                            }
                        }
                    }
                },
                {
                    field: 'battery',
                    title: $translate.instant("CONTAINER_OVERVIEW_BATTERY"),
                    width: '94px',
                    template: `#= typeof battery === 'number' && battery !== -1 ? battery.toFixed(1) : "N/A" #`,
                    lockable: false,
                    filterable: {
                        extra: true,
                        cell: {
                            showOperators: false,
                            operator: "lte",
                            delay: 200000,  // Adjust if necessary; this is a very long delay (200 seconds)
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        },
                        operators: {
                            number: {
                                gte: $translate.instant("GRID_FILTER_GTE_OPERATOR"),
                                lte: $translate.instant("GRID_FILTER_LTE_OPERATOR")
                            }
                        }
                    }
                },
                {
                    field: 'fill',
                    title: $translate.instant("CONTAINER_OVERVIEW_FILL_PERCENT"),
                    width: '94px',
                    template: `#= fill !== -1 ? fill : "N/A" #`,
                    lockable: false,
                    filterable: {
                        extra: true,
                        operators: {
                            number: {                                
                                gte: $translate.instant("GRID_FILTER_GTE_OPERATOR"),
                                lte: $translate.instant("GRID_FILTER_LTE_OPERATOR")
                            }
                        }
                    }
                },
                {
                    field: 'dateWhenFull',
                    title: $translate.instant("CONTAINER_OVERVIEW_DATE_WHEN_FULL"),
                    width: '94px',
                    lockable: false,
                    filterable: {
                        extra: true,
                        operators: {
                            date: {
                                eq: $translate.instant("GRID_FILTER_ET_OPERATOR"),
                                neq: $translate.instant("GRID_FILTER_NET_OPERATOR"),
                                gte: $translate.instant("GRID_FILTER_AET_OPERATOR"),
                                lte: $translate.instant("GRID_FILTER_BET_OPERATOR")
                            }
                        }
                    }
                },
                
            ];

            for (let index = 0; index < sensorSystemsCount; index++) {
                columns.push(...getDeviceOverviewColumns(index));
            }

            return columns;
        }

        function simulateEnter(event) {
            const inputElement = event.target;
            inputElement.addEventListener("keyup", function(event) {
                if (event.keyCode !== 13 && event.keyCode !== 0) {
                    inputElement.blur();
                    inputElement.focus();
                }
                getGridDisplayedRows();
            });                
        }

        function getGridDisplayedRows() {
            const grid = $("#container-overview-grid").data("kendoGrid");
            const dataSource = grid.dataSource;
            
            $scope.displayedLocationAmount = dataSource.total();
        }

        function getDeviceOverviewColumns(index) {
            const columns = [];
            columns.push(              
                {
                    field: `deviceOverviews[${index}].deviceExternalId`,
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_EXTERNAL_ID"), index),
                    width: '140px',
                    filterable: {
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        }
                    }
                },
                {
                    field: `deviceOverviews[${index}].externalSystem`,
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_EXTERNAL_SYSTEM"), index),
                    width: '120px',
                    template: `#=  deviceOverviews[${index}].sensorColumnTemplate #`,
                    filterable: {
                        multi: true,
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        }
                    },
                },
                {
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_CREATED_AT"), index),
                    field: `deviceOverviews[${index}].createdAt`,
                    width: '120px',
                    format: $translate.instant("CONTAINER_OVERVIEW_CREATED_AT_DATE_FORMAT"),
                    filterable: {
                        ui: 'datetimepicker',
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        }
                    },
                },
                {
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_DATE_WHEN_FULL"), index),
                    field: `deviceOverviews[${index}].dateWhenFull`,
                    width: '148px',
                    format: $translate.instant("CONTAINER_OVERVIEW_DATE_WHEN_FULL_DATE_FORMAT"),
                    filterable: {
                        ui: 'datetimepicker',
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        }
                    },
                },
                {
                    title: getIndexedColumnTitle($translate.instant("CONTAINER_OVERVIEW_LAST_EMPTY_DATE"), index),
                    field: `deviceOverviews[${index}].lastEmptyDate`,
                    width: '120px',
                    format: $translate.instant("CONTAINER_OVERVIEW_LAST_EMPTY_DATE_DATE_FORMAT"),
                    filterable: {
                        ui: 'datetimepicker',
                        cell: {
                            delay: 200000,
                            suggestionOperator: "contains",
                            dataTextField: "headerFilterTextBox"
                        }
                    },
                },
            );

            return columns;
        }

        function getIndexedColumnTitle(title, index) {
            if (index === 0) {
                return title;
            }

            return `${title} ${index + 1}`;
        }

        initController();

        function initController() {
            loadOverviewGridOptions();
            getContainerOverviewGridData();
            setTimeout(() => {                
                addFilterTextFieldEventListeners();
            }, 3000);
        }

        function addFilterTextFieldEventListeners() {
            const elementTextFields = document.querySelectorAll(`input[data-text-field="headerFilterTextBox"]`);

            if (!elementTextFields?.length) return;

            elementTextFields.forEach(element => {               
                element.addEventListener("keyup", simulateEnter, 1000);
            });
        }

        function loadOverviewGridOptions() {
            const unwatch = $scope.$watch('containerOverviewGrid', (grid) => {
                if (!grid) return;

                unwatch();
                const options = kendoGridUtility.loadOptions('container-overview-grid', overviewTableVersion);
                if (options) {
                    grid.setOptions(options);
                }
            })
        }

        function isEditVisible() {
            return $rootScope.isVisible('CORE', { Area: 'NewContainer', AccessType: 'W' });
        }

        function openNewContainerModal() {
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: $translate.instant("CONTAINER_OVERVIEW_NEW_CONTAINER_MODAL_TITLE"),
                    resizable: false,
                    height: 600,
                    width: 1000,
                    visible: false
                },
                templateUrl: 'app/containerOverviewV2/views/sensor-api-container-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SensorApiContainerDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.add;
                    },
                    containerId: function () {
                        return null;
                    },
                    updateCallbacks: function () {
                        return {
                            addContainerToGrid: () => { },
                            updateContainerToGrid: () => { },
                        };
                    },
                    locationId: function () {
                        return null;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    getContainerOverviewGridData();
                }
            });
        }

        function openUpdateContainerModal(dataItem) {
            var titleFraction = dataItem.fraction ? (' - ' + dataItem.fraction) : '';
            var titleAddress = dataItem.address ? (' - ' + dataItem.address) : '';
            var windowInstance = $kWindow.open({
                options: {
                    modal: true,
                    movable: false,
                    title: dataItem.containerNumber + titleAddress + titleFraction,
                    resizable: false,
                    height: 600,
                    width: containerEditModalWidth,
                    visible: false
                },
                templateUrl: 'app/containerOverviewV2/views/sensor-api-container-details-modal-view.html',
                windowTemplateUrl: 'app/shared/modal-base.html',
                controller: 'SensorApiContainerDetailsModalController',
                resolve: {
                    currentFormType: function () {
                        return formTypes.edit;
                    },
                    containerId: function () {
                        return dataItem.id;
                    },
                    updateCallbacks: function () {
                        return {
                            addContainerToGrid: () => { },
                            updateContainerToGrid: () => { },
                        };
                    },
                    locationId: function () {
                        return null;
                    }
                }
            });

            windowInstance.result.then(function (result) {
                if (result) {
                    getContainerOverviewGridData();
                }
            });
        }

        function exportToExcel() {
            $scope.containerOverviewGrid.hideColumn(0);
            $scope.containerOverviewGrid.saveAsExcel();
            $scope.containerOverviewGrid.showColumn(0);
        }

        //Private functions
        function getContainerOverviewGridData() {
            $rootScope.$broadcast('showBusyIndicator', {
                id: 'containerIndicator',
                destination: '#container-overview-view-v2',
                message: $translate.instant('G_BUSY_INDICATOR'),
                overlay: true,
                positionClass: {
                    top: '50%',
                    left: '0px',
                    right: '0px'
                }
            });

            containerOverviewGridData = [];
            var requests = [
                locationsService.getLocations(false),
                containerService.getContainersV2($stateParams.noCache)
            ];
            $q.all(requests)
                .then(function (data) {
                    var allLocations = data[0];
                    var containers = data[1];

                    if($rootScope.isSuperAdmin() || $rootScope.isAdmin()) {
                        locations = allLocations;    
                    } else {
                        locations = allLocations.filter(l => $rootScope.getMunicipalityAccess(l.municipalityCode));
                        containers = containers.filter(c => locations.some(l => l.id === c.locationId));
                    }

                    if (containers) {
                        containerOverviewGridData = containers;
                        var sensorSystemsCount = getCurrentSensorSystemColumnCount();
                        containerOverviewGridData.forEach(c => extendGridData(c, sensorSystemsCount));
                        $scope.containerOverviewGrid.dataSource.data(containerOverviewGridData);
                        $scope.allLocationAmount = containerOverviewGridData.length;
                        const grid = $('#container-overview-grid').getKendoGrid();
                        grid.autoFitColumn('containerType');
                        grid.autoFitColumn('locationName');
                        grid.autoFitColumn('displayText');
                        getGridDisplayedRows();
                        loadFilterFromLocalStorage();
                    }

                })
                .finally(() => $rootScope.$broadcast('hideBusyIndicator', 'containerIndicator'));
        }
      
        function extendGridData(container, sensorSystemsCount) {
            var location = container.locationId ? locations.find(loc => loc.id === container.locationId) : null;

            container.id = container.containerId;
            container.locationName = location ? location.name : '';
            container.commune = location ? location.municipalityCode : '';

            setContainerSignals(container);
            setContainerBatteries(container);
            setContainerFill(container);            
            setLocationRef(container);
            padDeviceOverviews(container, sensorSystemsCount);
            container.deviceOverviews.forEach(extendDeviceOverview);
        }

        function extendDeviceOverview(device) {
            device.createdAt = toDateOrEmpty(device.createdAt);
            device.dateWhenFull = toDateOrEmpty(device.dateWhenFull);
            device.lastEmptyDate = toDateOrEmpty(device.lastEmptyDate);
            device.sensorColumnTemplate = getSensorColumnTemplate(device)

            // Negative numbers are used to represent empty values so sorting works
            device.battery = device.battery || -1;
            device.fill = device.fill || -1;
        }

        function getSensorColumnTemplate(device) {
            let template = device.externalSystem || '';

            const detailsLink = deviceUtility.getDeviceDetailsLink(device.externalSystem, device.externalSystemId, device.deviceExternalId, true);
            if (detailsLink) {
                template += ` <a class="grid-tooltip" href="${detailsLink.url}" target="_blank"><i class="fa fa-external-link"></i></a>`;
            }

            return template;
        }

        function padDeviceOverviews(item, sensorSystemsCount) {
            for (let index = item.deviceOverviews.length; index < sensorSystemsCount; index++) {
                item.deviceOverviews[index] = {};
            }
        }

        function addContainerToGrid(container) {
            containerOverviewGridData.push(container);
            extendGridData(container, getCurrentSensorSystemColumnCount());
            $scope.containerOverviewGrid.dataSource.data(containerOverviewGridData);
        }

        function updateContainerToGrid(container) {
            var containerIndexOverview = _.findIndex(containerOverviewGridData, c => c.containerId == container.containerId);
            containerOverviewGridData.splice(containerIndexOverview, 1);

            containerOverviewGridData.push(container);
            extendGridData(container, getCurrentSensorSystemColumnCount());
            $scope.containerOverviewGrid.dataSource.data(containerOverviewGridData);
        }

        function toDateOrEmpty(date) {
            return date == null ? '' : new Date(date);
        }

        function getCurrentSensorSystemColumnCount() {
            return defaultSensorSystemCount;
        }

        function onRowSelected() {
            var selectedRows = this.select();
            $rootScope.selectedContainerId = this.dataItem(selectedRows[0]).id;
        }

        function containerOverviewGridExcelExport(e) {
            e.workbook.fileName = $translate.instant("CONTAINER_OVERVIEW_CONTAINER_OVERVIEW_FILENAME") + kendo.toString(new Date, 'ddMMyyyy_HHmmss') + '.xls';
            var sheet = e.workbook.sheets[0];
            var columnWidths = this.element.find('.k-grid-header colgroup col');

            for (var i = 0; i < sheet.columns.length; i++) {
                if ($(columnWidths[i]).attr('style')) {
                    sheet.columns[i].width = 160;
                } else {
                    sheet.columns[i].width = 16;
                }
            }
        }

        // the priority is fill priority, this column can be removed and migrated to sensor overview if they approve it
        function setContainerSignals(container) {
            if (container.deviceOverviews.length > 0) {
                container.deviceOverviews.forEach(device => {
                    if (typeof device.signal === 'number') {
                        if (device.deviceIsPriority) {
                            container.signal = device.signal;
                        } else if (!container.signal) {
                            container.signal = device.signal;
                        }
                    }
                });
            }
            if (typeof container.signal !== 'number') {
                container.signal = ' ';
            }
            container.SignalClass = getSignalStrength(container.signal);
        }

        // the priority is fill priority, this column can be removed and migrated to sensor overview if they approve it
        function setContainerBatteries(container) {
            if (container.deviceOverviews.length > 0) {
                container.deviceOverviews.forEach(device => {
                    if (typeof device.battery === 'number') {
                        if (device.deviceIsPriority) {
                            container.battery = device.battery;
                        } else if (!container.battery) {
                            container.battery = device.battery;
                        }
                    }
                });
            }
            if (typeof container.battery !== 'number') {
                container.battery = ' ';
            }
        }

        // set priority device's fill or the first fill found to container fill
        function setContainerFill(container) {
            if (container.deviceOverviews.length > 0) {
                container.deviceOverviews.forEach(device => {
                    if (typeof device.fill === 'number') {
                        if (device.deviceIsPriority) {
                            container.fill = device.fill;
                            container.dateWhenFull = device.dateWhenFull;
                        } else if (device.fill && !container.fill) {
                            container.fill = device.fill;                            
                            container.dateWhenFull = device.dateWhenFull;
                        }
                    }
                });
            }
            if (typeof container.fill !== 'number') {
                container.fill = ' ';
            }
        }

        function getSignalStrength(signal) {
            if (signal <= 5) { return 'critical'; }
            else if (signal > 5 && signal <= 7) { return 'warning'; }
            else if (signal > 7) { return 'ok'; }
            else { return 'missing' }
        }

        function setLocationRef(container) {
            let locationHref = '';
            if (container && container.locationId) {
                locationHref = location.origin + '/main/location/' + container.locationId + '/info';
            }
            container.locationHref = locationHref;
        }

        function saveGroupedStatusToLocalStorage() {
            setTimeout(() => {
            var grid = $("#container-overview-grid").getKendoGrid();
            var grouped = grid.dataSource.group();
            localStorage.setItem('containerOverviewGroup', kendo.stringify(grouped));
            }, 100);
        }

        function loadFilterFromLocalStorage() {
            var grouped  = localStorage.getItem('containerOverviewGroup');
            var grid = $("#container-overview-grid").data("kendoGrid");
            if (grouped ) {
                grid.dataSource.group(JSON.parse(grouped ));
            } else {
                grid.dataSource.group(defaultGrouping);
            }
        }
        
    }
})();
